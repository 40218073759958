.background-login {
  background-image: url('../../images/login/fondo.jpg');
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-login {
  width: 432px;
  background: linear-gradient(
      135.93deg,
      rgba(240, 240, 240, 0.6) 7.8%,
      rgba(245, 243, 243, 0) 99.59%
    ),
    #f8f8f8;
  border-radius: 20px;
  &__logo {
    width: 100%;
    height: 384px;
    background-color: $main-black;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__google {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__label {
    font-size: 20px;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: flex-end;
    width: 80%;
    margin-bottom: 10px;
  }
  &__button {
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 40px;
    background-color: $main-white;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(20, 20, 20, 0.05);
  }
  &__span {
    display: flex;
    justify-content: center;
    width: 70%;
    font-size: 16px;
    font-weight: 500;
  }
  &__error{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $error-color;
    width: 80%;
    margin:5px 0px 20px 0px;
  }
  &__no-error{
    padding: 28px;
  }
}
