.optionproject{
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    &__textarea{
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    &__title{
        width: 100%;
        font-weight: 600;
        font-size: 34px;
    }
    &__subtitle{
        width: 100%;
        font-size: 12px;
        font-weight: normal;
    }
    
}

.paginador-area{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.paginador{
    width: 30%;
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    overflow-x: scroll;
    &__arrow{
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $main-black;
        cursor: pointer;
        margin-right: 15px;
    }
    &__page{
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-light;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        margin-right: 15px;
    }
}

.project {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      
      height: 160px;
      border-radius: 10px 10px 0px 0px;
    }
    &__content1{
        background-color: $blue-tita;
    }
    &__content2{
        background-color: $green-tita;
    }
    &__content3{
        background-color: $yellow-tita;
    }
    &__content-head {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
    &__content-head-title {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 12px;
      font-weight: 400;
      background: linear-gradient(
        180deg,
        rgba(20, 20, 20, 0.5) 0%,
        rgba(20, 20, 20, 0.02) 100%
      );
      border-radius: 20px;
      color: $main-white;
    }
  
    &__content-footer {
      width: 90%;
      height: 21px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
    
    }
    &__content-footer-item {
    
      width: 20px;
      display: flex;
      justify-content: center;
      color: $main-white;
      border-radius: 50%;
      background-color: $main-black;
      border: 1px solid $main-white;
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
    }
   &__content-footer-item-img{
     width: 100%;
     height: 100%;
   }
    &__footer {
      border-radius: 0px 0px 20px 20px;
      background-color: $gray-basic;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
   
  }