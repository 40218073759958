*,
*::before,
*:after {
  box-sizing: border-box;
}

html,body{
    height: 100vh;
    width: 100%;
}
body{
    margin: 0;
    font-family: $fontfamily;
    font-size: 14px;
}

button{
  background: none;
  border: none;
  outline: none;
  font-family: inherit; 
}

input{
  border: none;
  outline: none;
  background-color: none;
}

a{text-decoration: none; color: $main-black;}

[hidden] {
  display: none!important;
}