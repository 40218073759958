$fontfamily: 'Be Vietnam', sans-serif;
$main-black:  #141414;
$main-white: white;
$gray-basic: #F8F8F8;
$gray-light: #F2f2f2;
$gray-basic-midle: #BDBDBD;
$green-tita: #80E593;
$green-strong: #001305;
$blue-tita: #52C7CF;
$yellow-tita: #F2C94C;
$gray-semidle:#F2F2F2;
$gray-midle: #E0E0E0;
$gray-semisold: #7B7B7B;
$gray-sold: #828282;
$gren-pale:#E9FAEC;
$error-color:#EB5757;
