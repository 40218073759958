.header-assignment {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.25px;
    width: 40%;
  }
  &__select {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $gray-light;
    border-radius: 4px;
    padding: 6.5px 0;
    margin-bottom: 2px;
    position: relative;
    margin-right: 30px;
  }
  &__team {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $gray-semisold;
  }
  &__whichteam {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $main-black;
  }
  &__arrow {
    margin-top: 4px;
    width: 10px;
    height: 6px;
  }
  &__button {
    position: relative;
    border: 4px solid transparent;
    border-radius: 8px;
    background-color: $main-white;
    background-clip: padding-box;
    font-weight: bold;
    font-size: 14px;
    color: $green-tita;
    width: 25%;
    padding: 5px 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }
  &__button::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(to bottom, $green-tita 0%, $blue-tita 100%);
    content: "";
    z-index: -1;
    border-radius: 5px;
  }
  &__orderby {
    display: flex;
    width: 40%;
    font-weight: 700;
    font-size: 14px;
    color: $green-tita;
    justify-content: flex-end;
    margin-right: 2px;
  }
  &__spanoption {
    display: flex;
    width: 55%;
    font-weight: 700;
    font-size: 14px;
    color: $main-black;
  }
  &__hide {
    display: none;
  }

  &__show {
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 36px;
    width: 100%;
    background-color: $gray-basic;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 500px;
    overflow-y: auto;
    padding: 5px 5px;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 5px 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    border-radius: 5px;
  }

  &__item:hover {
    background-color: $gray-light;
  }
}

.assigntableheader {
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $gray-basic;

  &__dates {
    width: 50%;
    display: flex;
    padding-left: 25px;
  }
  &__date {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.15px;
  }
  &__arrows {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }
  &__arrow:nth-child(1) {
    margin-right: 20px;
  }
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $gray-sold;
  }
  &__hours-space-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__check {
    display: flex;
    align-items: center;
    width: 45%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}

.assigncalenheader {
  width: 100%;
  display: flex;
  &__members {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.4px;
  }
  &__use {
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.4px;
  }
  &__space {
    width: 3%;
  }
  &__space_change {
    width: 5%;
  }
  &__arrow:nth-child(2) {
    width: 9%;
  }
  &__arrow {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__day {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
  &__day_end {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $main-white;
    background-color: $main-black;
  }
  &__sum {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
}
.assigncalenbody {
  width: 100%;
  height: 45px;
  display: flex;
  background-color: $gray-basic;
  img{
    cursor: pointer;
  }
  &__members {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 40px;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
  }
  &__name-item {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    background-color: $main-black;
    border: 1px solid white;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
  }
  &__imageperfil {
    margin-right: 10px;
  }
  &__use {
    width: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.4px;
  }
  &__space {
    width: 2%;
    border-top: 1px solid $gray-sold;
  }
  &__space_change {
    width: 3.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $gray-sold;
  }
  &__arrow {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $gray-basic-midle;
    border-top: 1px solid $gray-sold;
  }
  &__arrow-label {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__day {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
  &__est {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $gray-midle;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
  }

  &__danger {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-white;
    background-color: $error-color;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
  }

  &__good {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $green-tita;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
  }

  &__real {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $gray-light;
    border-left: 1px solid $gray-sold;
  }
  &__day_end {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $main-white;
    background-color: $main-black;
  }
  &__sum {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    color: $main-black;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: $gray-midle;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
    border-right: 1px solid $gray-sold;
  }
  &__sum-total {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    color: $main-black;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: $gren-pale;
    border-top: 1px solid $gray-sold;
    border-right: 1px solid $gray-sold;
  }
  &__nametask {
    color: $gray-semisold;
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 23px;
  }

  &__sync-icon {
    height: 24px;
    cursor: pointer;
  }
}

.assigcalenfooter {
  width: 100%;
  display: flex;
  height: 40px;
  background-color: $gray-midle;
  margin-bottom: 30px;
  &__space {
    width: 47%;
    border: 1px solid $gray-sold;
  }
  &__total {
    width: 5%;
    color: $main-white;
    background-color: $main-black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__great-total {
    width: 9%;
    color: $main-white;
    background-color: $main-black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__space-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: $green-tita;
    border: 1px solid $gray-sold;
  }
}

.assignment-item {
  &__hide {
    display: none;
  }

  &__show {
    animation-duration: 0.2s;
    animation-name: slidein;
    display: inline;
  }

  &__success {
    position: fixed;
    animation-duration: 0.2s;
    animation-name: slide;
    background-color: $green-tita;
    bottom: 100px;
    right: 0px;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    color: $main-white;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    z-index: 100;
  }

  &__fail{
    position:fixed;
    animation-duration: 0.2s;
    animation-name: slide;
    background-color: $error-color;
    bottom: 30px;
    right: 0px;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    color:$main-white;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    z-index: 100;
  }

  &__content{
    position: relative;
  }

  &__loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-light;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.assigncalentask {
  width: 100%;
  min-height: 45px;
  display: flex;
  background-color: $gray-basic;
  position: relative;

  &__success{
    position: fixed;
    animation-duration: 0.2s;
    animation-name: slide;
    background-color: $green-tita;
    bottom: 100px;
    right: 0px;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    color:$main-white;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    z-index: 100;
  }

  &__hide{
    display: none;
  }

  &__fail{
    position: fixed;
    animation-duration: 0.2s;
    animation-name: slide;
    background-color: $error-color;
    bottom: 30px;
    right: 0px;
    padding: 20px;
    border-radius: 20px 0px 0px 20px;
    color:$main-white;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    z-index: 100;
  }

  &__paper{
    background-color: $main-white;
    width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__m-header{
    background-color: $main-black;
    color: $main-white;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 50px;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    letter-spacing: 0.25px;
  }

  &__m-body{
    font-size: 16px;
    line-height: 23px;
    padding: 10px 50px;
  }

  &__m-select{
    padding: 0px 50px;
  }

  &__m-select .assignment-form-select__whichteam{
    font-size: 13px;
  }

  &__m-select &__m-body{
    padding: 10px 0px;
  }

  &__m-textarea{
    margin: 10px 50px;
    font-size: 14px;
    line-height: 23px;
  }

  &__m-textarea-error{
    border: 2px solid $error-color;
    margin: 10px 50px;
    font-size: 14px;
    line-height: 23px;
  }

  &__m-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  &__m-green-button{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $main-white;
    background-color: $green-tita;
    border-radius: 10px;
    height: 38px;
    width: 130px;
    margin-right: 20px;
    cursor: pointer;
  }

  &__m-green-button:hover{
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__m-gray-button{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $main-white;
    background-color: $gray-semisold;
    border-radius: 10px;
    height: 38px;
    width: 130px;
    cursor: pointer;
  }

  &__m-gray-button:hover{
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__unlike-icon{
    height: 24px;
    position: absolute;
    left: 10px;
    top:12px;
    cursor:pointer;
  }

  &__unlike-icon2{
    height: 24px;
    position: absolute;
    left: 37px;
    top:12px;
    cursor:pointer;
  }

  &__members {
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 70px;
    color:$gray-semisold;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
    position: relative;
  }

  &__project {
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
    margin-left: 10px;
    color: $main-black;
    border: 1px solid $main-black;
    padding: 0px 5px;
  }
  &__name-item {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    background-color: $main-black;
    border: 1px solid white;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
  }
  &__imageperfil {
    margin-right: 10px;
  }
  &__use {
    width: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.4px;
  }
  &__space {
    width: 3%;
  }
  &__space_change {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $gray-basic-midle;
    border-top: 1px solid $gray-sold;
  }
  &__arrow-label {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__day {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
    border-top: 1px solid $gray-sold;
  }
  &__est {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $gray-midle;
    text-align: center;
    border-left: 1px solid $gray-sold;
  }
  &__danger {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-white;
    background-color: $error-color;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
    text-align: center;
  }
  &__real {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $gray-light;
    text-align: center;
    border-left: 1px solid $gray-sold;
  }
  &__empty {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    text-align: center;
    background-color: $gray-basic;
    border-left: 1px solid $gray-sold;
  }
  &__edited {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $main-black;
    background-color: $green-tita;
    text-align: center;
    border-left: 1px solid $gray-sold;
  }
  &__day_end {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $main-white;
    background-color: $main-black;
  }
  &__sum {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    color: $main-black;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: $gray-midle;
    border-left: 1px solid $gray-sold;
    border-right: 1px solid $gray-sold;
  }
  &__sum-total {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    color: $main-black;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    background-color: $gren-pale;
    border-right: 1px solid $gray-sold;
  }
  &__nametask {
    color: $gray-semisold;
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 23px;
  }

  &__form {
    width: 42%;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 40px;
    color: $gray-semisold;
    border-left: 1px solid $gray-sold;
    border-top: 1px solid $gray-sold;
  }

  &__form-input-space {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $gray-light;
    border-radius: 4px;
    padding: 3px 0;
    position: relative;
  }

  &__form-label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $gray-semisold;
    padding-right: 10px;
    padding-left: 20px;
  }

  &__form-input {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $main-black;
    background-color: transparent;
    width: 73%;
  }
  &__cancel-space {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px;
    padding: 3px 0;
    position: relative;
  }

  &__cancel-button {
    border: 1px solid $main-black;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    padding: 2px 20px;
  }

  &__cancel-button:hover {
    background-color: $gray-light;
  }

  &__loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-light;
    opacity: 0.9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  &__task-link:hover {
    cursor: pointer;
    color: $blue-tita;
  }

  &__logo-comment {
    margin-left: 5px;
    width: 5%;
    height: auto;
    display: flex;
    cursor: pointer;
  }
}

.assignment-form-select {
  &__hide {
    display: none;
  }

  &__show {
    position: absolute;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 26px;
    width: 100%;
    background-color: $gray-basic;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 5px;
  }

  &__show::-webkit-scrollbar {
    display: none;
  }

  &__select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-light;
    border-radius: 4px;
    padding: 3px 0;
    margin-bottom: 4px;
    position: relative;
    min-height: 26px;
  }

  &__team {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $gray-semisold;
    padding-right: 10px;
    padding-left: 20px;
  }
  &__whichteam {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $main-black;
  }
  &__arrow {
    margin-top: 4px;
    width: 10px;
    height: 6px;
    margin-right: 20px;
    margin-left: 10px;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 5px 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    border-radius: 50px;
  }

  &__item:hover {
    background-color: $gray-light;
  }

  &__input-space {
    background-color: $main-white;
    color: $main-black;
    width: 100%;
    padding: 5px 15px;
    border-radius: 50px;
    align-items: center;
    display: flex;
  }

  &__input {
    background-color: transparent;
    width: 90%;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  &__item-list {
    max-height: 120px;
    overflow-y: scroll;
    width: 100%;
  }

  &__item-list::-webkit-scrollbar {
    display: none;
  }
}

.assignment-table {
  border-collapse: collapse;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.imgr {
  -webkit-animation: 3s rotate linear infinite;
  animation: 3s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.assignment-popup {
  width: 250px;
  border-radius: 5px;
  border: solid 1px $main-black;
  padding: 10px;
  background-color: $gray-basic;

  &__comment {
    margin-top: 5px;
    width: 100%;
    height: 60px;
    border: 1px solid $gray-basic-midle;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
    resize: none;
    font-family: "Be Vietnam", sans-serif;
  }

  &__span{
    width: 60%;
    font-size: 13px;
  }

  &__comment:focus {
    outline: none !important;
    border-color: $main-black;
    outline-color: none;
  }

  .inputpopover__input:focus {
    outline: none !important;
    border-color: $main-black;
    outline-color: none;
  }

  &__btn-comment{
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $main-white;
    background-color: $green-tita;
    border-radius: 10px;
    height: 30px;
    width: 100px;
    cursor: pointer;
    margin-top: 3px;
  }

  &__btn-comment:hover{
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__cancel{
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $main-white;
    background-color: $gray-semisold;
    border-radius: 10px;
    height: 30px;
    width: 100px;
    cursor: pointer;
    margin-top: 3px;
  }

  &__cancel:hover{
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inputpopover {
  width: 40%;
  display: flex;
  flex-direction: column;

  &__input {
    width: 100%;
    height: 25px;
    border: 1px solid $gray-basic-midle;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
    font-family: "Be Vietnam", sans-serif;
  }
}

.dialog-story {
  &__btn-close {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $main-white;
    background-color: $gray-semisold;
    border-radius: 10px;
    height: 38px;
    width: 130px;
    cursor: pointer;
    margin: 5px 32px;
  }

  &__btn-close:hover{
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__container-modal {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.15px;
    font-family: "Be Vietnam", sans-serif;
  }

  &__container_comment {
    font-size: 14px;
    margin: 10px;
    margin-top: 0px;
    padding: 20px 15px 20px 40px;
    background: $gray-light;
    border-radius: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    font-weight: 600;
  }

  &__container_comment-text {
    font-weight: 500;
    color: $gray-semisold;
    word-wrap: break-word;
  }
}

.MuiTypography-h6 {
  font-family: "Be Vietnam", sans-serif !important;
  color: $main-black !important;
  font-weight: bold !important;
}

.MuiCircularProgress-colorPrimary {
  color: $main-black !important;
  margin-left: 5px !important;
}

.MuiDialogContent-root {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px !important;
}

.MuiDialog-paperWidthSm {
  width: 600px;
}

.MuiDialogTitle-root{
  background-color: #141414;
  color: white;
  border-radius: 3px 3px 0px 0px;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  letter-spacing: 0.25px;
  margin-bottom: 20px !important;
}

.MuiDialogTitle-root .MuiTypography-h6{
  color: $main-white !important;
}   
