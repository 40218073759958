.showdetailproject {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 95%;
  min-height: 100vh;
  background-color: $main-white;
  z-index: 99;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: -10px 10px 10px rgba(20, 20, 20, 0.05);

  &__main {
    width: 93%;
  }
  &__main-header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid $gray-basic-midle;
    margin-bottom: 20px;
  }
  &__close {
    cursor: pointer;
  }
  &__header-button {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $main-black;
    font-size: 14px;
    font-weight: bold;
  }
  &__footer {
    width: 100%;
    background-color: $green-strong;
    display: flex;
    justify-content: center;
  }
}
.menuprojectdetail {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title-functions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
  }
  &__functions {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  &__save-actions {
    width: 25%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $green-tita;
    border-radius: 10px;
    margin-right: 10px;
    color: $main-white;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
  &__functions-button {
    width: 15%;

    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: $gray-semidle;
  }
  &__main-tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid $gray-basic-midle;
  }
  &__container-main-tabs {
    width: 50%;
    display: flex;
    justify-content: flex-start;
  }

  &__main-tab {
    width: 25%;
    display: flex;
    flex-direction: column;
    color: $gray-semisold;
    font-weight: normal;
    font-size: 16px;
    cursor: pointer;
  }
  &__main-tab-activity {
    width: 50%;
    height: 3px;
    background: linear-gradient(139.83deg, #80e593 3.8%, #52c7cf 82.11%);
    margin-top: 5px;
  }
  &__main-tab-shedulling {
    width: 75%;
    height: 3px;
    background: linear-gradient(139.83deg, #80e593 3.8%, #52c7cf 82.11%);
    margin-top: 5px;
  }
}

.contentdetailproject {
  width: 100%;
  display: flex;

  &__menu-activity {
    width: 15%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }
  &__label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    color: $gray-semisold;
    height: 35px;
  }
  &__main-content {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footercard {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
  }
  &__form-image {
    height: 50%;
  }
  &__form-input {
    width: 70%;
    height: 40px;
    padding-left: 15px;
    margin-left: 20px;
    border-radius: 8px;
    font-family: Be Vietnam;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  &__form-input::placeholder {
    font-family: Be Vietnam;
    font-style: normal;
    font-weight: normal;
    color: $gray-semisold;
    font-size: 14px;
  }
  &__options {
    width: 85%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  &__collaborators {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__collaborators-label {
    color: $gray-basic;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-right: 15px;
  }
  &__collaborators-image {
    margin-right: 5px;
  }
  &__collaborators-plus {
    background-color: $green-tita;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  &__options-content-button {
    width: 40%;
    position: relative;
    z-index: 9;
  }
  &__options-button {
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
    background-color: $green-strong;
    font-weight: bold;
    font-size: 14px;
    color: $green-tita;
    padding: 7px 0 5px 0;
    width: 75%;
  }
  &__options-button::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(to bottom, #80e593 0%, #52c7cf 100%);
    content: "";
    z-index: -1;
    border-radius: 5px;
  }
  &__content-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 80px;
    border-radius: 4px;
  }
  &__icon-add {
    width: 70%;
    cursor: pointer;
  }
  &__inputfile {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  &__content-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  }
}

.headeractivity {
  width: 100%;
  height: 225px;
  display: flex;
  align-items: flex-end;

  &__content {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
  }
  &__perfil {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  &__input {
    width: 300px;
    border: 0.5px solid $gray-basic-midle;
    border-radius: 4px;
    height: 25px;
    padding-left: 10px;
  }
  &__listsuser {
    width: 300px;
    position: absolute;
    max-height: 100px;
    top: 26px;
    z-index: 9;
    background-color: $main-white;
    border: 1px solid $gray-basic-midle;
    overflow-y: scroll;
    border-radius: 3.5px;
  }
  &__listuser {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
  }

  &__listuser:hover {
    background-color: $gray-basic;
  }
  &__dates {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  &__date {
    width: 30%;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__date-range {
    position: absolute;
    top: 25px;
    z-index: 9;
    box-shadow: 2px 4px 13px -3px rgba(189, 189, 189, 1);
  }
  &__date-label {
    cursor: pointer;
  }
  &__date_gray {
    width: 33%;
    color: $gray-semisold;
  }
  &__projects {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }
  &__projects-title {
    width: 33%;
    display: flex;
  }
  &__projects-image {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-tita;
    border-radius: 15px;
    margin-right: 8px;
  }
  &__projects-img {
    width: 70%;
  }

  &__percentage {
    width: 100%;
    margin-bottom: 10px;
  }
}

.description {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  &__textarea {
    font-family: Be Vietnam;
    width: 100%;
    padding: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
    resize: vertical;
    outline: none;
    border: 0.5px solid $gray-basic-midle;
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.resourcesProject {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  &__content-image {
    margin-right: 10px;
    width: 150px;
    height: 82px;
    border-radius: 4px;
    border: 0.5px solid $gray-basic-midle;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    word-wrap: break-word;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  &__content-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 80px;
    border: 1px dashed $gray-semisold;
    border-radius: 4px;
  }
  &__icon-add {
    cursor: pointer;
  }
  &__inputfile {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  &__content-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  }
}

.comentsproject {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__coment {
    background-color: $gray-semidle;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
    width: 97%;
    margin-bottom: 10px;
  }
  &__coment-img {
    margin-right: 10px;
  }
  &__coment-who {
    margin-right: 10px;
  }
  &__coment-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
  }
  &__text {
    width: 97%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
    text-align: center;
    margin-bottom: 10px;
  }
  &__responsable {
    background-color: $gray-semidle;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
    width: 97%;
    margin-bottom: 10px;
  }
  &__responsable-img {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__responsable-coment {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  &__responsable-coment-title {
    margin-bottom: 10px;
  }
  &__responsable-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: 10px;
  }
  &__responsable-span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
  }
  &__responsable-coment-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
    padding: 0 15px 0 0;
  }
  &__responsable-letter {
    background-color: $main-black;
    color: $main-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  &__file {
    width: 150px;
    height: 82px;
    border-radius: 4px;
    border: 0.5px solid $gray-basic-midle;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    word-wrap: break-word;
    padding: 10px;
    cursor: pointer;
  }
}
.shedulling {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    height: 70px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  &__hours {
    height: 100px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $gray-basic;
  }
  &__hours-space-1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
  }
  &__hours-text1 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $gray-semisold;
  }
  &__hours-text2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
  &__hours-space-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__check {
    display: flex;
    align-items: center;
    width: 45%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}
.headercalendar {
  width: 100%;
  display: flex;
  &__calendar {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  &__calendar-img {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__space {
    width: 5%;
  }
  &__arrow {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  &__day {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
  &__day_end {
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $main-white;
    background-color: $main-black;
  }
  &__sum {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
}
.bodycalendarmain {
  width: 100%;
  background-color: $gray-basic;
}
.bodycalendar {
  width: 100%;
  display: flex;
  height: 60px;
  &__calendar {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    position: relative;
  }
  &__users {
    width: 90%;
    max-height: 100px;
    border: 1px solid $gray-basic-midle;
    background-color: $gray-basic;
    border-radius: 3.5px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45px;
    z-index: 9;
    overflow-y: scroll;
  }
  &__user {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    min-height: 30px;
    cursor: pointer;
  }
  &__user:hover {
    background-color: $main-white;
  }
  &__input {
    width: 90%;
    height: 30px;
    border: 1px solid $gray-basic-midle;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
    background: none;
  }
  &__input-hours {
    width: 100%;
    text-align: center;
    background-color: $gray-midle;
  }
  &__calendar-img {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__space {
    width: 5%;
  }
  &__arrow {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-black;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__day {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    outline: none;
  }

  &__day_active {
    background-color: $gray-midle;
  }
  &__sum {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: $gray-semisold;
  }
}
.footercalendar {
  width: 100%;
  display: flex;
  height: 40px;
  &__space {
    width: 45%;
  }
  &__total {
    width: 5%;
    color: $main-white;
    background-color: $main-black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__great-total {
    width: 10%;
    color: $main-white;
    background-color: $main-black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__space-arrow {
    width: 10%;
  }
}
