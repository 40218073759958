@import './config/variables';
@import './base/base.scss';

@import './login/login.scss';
@import './home/home.scss';
@import './proyects/projects.scss';
@import './proyects/detailproject.scss';
@import './showdetailproject/showdetailproject.scss';
@import './assignment/assignment.scss';
@import './users/users.scss';


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }


::-webkit-scrollbar {
  width: 12px;
  border-radius: 4px;
  border-left: 1px solid transparent;
}
::-webkit-scrollbar-thumb {
  background: $gray-semisold;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}


