.optionsdetail {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  &__areatext {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__areatext:nth-child(1) {
    width: 40%;
    margin-right: 3%;
  }
  &__areatext:nth-child(2) {
    width: 50%;
  }
  &__image {
    width: 17px;
    height: 10px;
  }
  &__label {
    font-size: 14px;
    font-weight: 400;
    color: $gray-semisold;
  }
}
.rowprojectdetail {
  width: 100%;
  display: flex;
  height: 65px;
  &__areatext {
    display: flex;
    align-items: center;
  }
  &__areatext:nth-child(1) {
    width: 40%;
    margin-right: 3%;
  }
  &__areatext:nth-child(2) {
    width: 51%;
    display: flex;
    justify-content: space-between;
  }
  &__check-task {
    width: 90%;
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
  &__check {
    margin-right: 20px;
  }
  &__task {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  &__comment-numeric {
    width: 10%;
    display: flex;
    align-items: center;
  }
  &__comment {
    margin-right: 5px;
  }
  &__numeric {
    font-weight: bold;
    font-size: 14px;
    color: $gray-semisold;
  }
  &__responsable {
    width: 37%;
    display: flex;
    align-items: center;
  }
  &__responsable-img {
    margin-right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  &__responsable-letter {
    background-color: $main-black;
    color: $main-white;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    border-radius: 50%;
  }
  &__date {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__date-range {
    position: absolute;
    top: 25px;
    z-index: 9;
    box-shadow: 2px 4px 13px -3px rgba(189, 189, 189, 1);
  }
  &__date-label {
    cursor: pointer;
    min-width: 175px;
    display: flex;
    justify-content: center;
  }
  &__percentage {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__percentage-value {
    width: 40%;
    display: flex;
    justify-content: center;
    background-color: $green-tita;
    border-radius: 20px;
  }
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: $green-tita;
}

.optiondetail {
  width: 100%;
  display: flex;
  margin-bottom: 30px;

  &__textarea {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  &__title {
    width: 100%;
    font-weight: 600;
    font-size: 34px;
  }
  &__subtitle {
    width: 100%;
    font-size: 12px;
    font-weight: normal;
  }
  &__buttonarea {
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }
  

  &__filter {
    width: 40%;
    height: 37px;
    border-radius: 8px;
    border: 1px solid $main-black;
    position: relative;
  }
  &__filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
  }
  &__orderby {
    display: flex;
    width: 40%;
    font-weight: 700;
    font-size: 14px;
    color: $green-tita;
    justify-content: flex-end;
    margin-right: 2px;
  }
  &__spanoption {
    display: flex;
    width: 55%;
    font-weight: 700;
    font-size: 14px;
    color: $main-black;
  }
  &__board-active{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $main-white;
    background-color: $green-tita;
    padding: 7.5px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
  &__board {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    border: 1px solid $main-black;
    border-radius: 4px;
    padding: 7px 30px;
    cursor: pointer;
  }
  &__list {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    border: 1px solid $main-black;
    border-radius: 4px;
    padding: 7px 30px;
    cursor: pointer;
  }
  &__list-active {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $main-white;
    background-color: $green-tita;
    padding: 7.5px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.filteroptions {
  width: 100%;
  position: absolute;
  top: 37px;
  border: 1px solid $gray-basic-midle;
  background-color: $main-white;
  border-radius: 3.5px;
  z-index: 9;
  &__option {
    width: 100%;
    height: 30px;
    background-color: $main-white;
  }
  &__option:hover {
    background-color: $gray-basic;
    cursor: pointer;
  }
}

.optionssprint {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  &__area {
    width: 55%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  &__arrowtext {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__arrow {
    margin-top: 5px;
    margin-right: 30px;
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.15px;
  }
  &__addmenu {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__add {
    margin-right: 20px;
    cursor: pointer;
  }
}

.board {
  width: 95%;
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  min-height: 100%;
  &__content {
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }
  &__column {
    width: 100%;
  }

  &__addsection {
    border: 1px solid $main-black;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    min-width: 150px;
    padding: 5px 0;
    cursor: pointer;
  }
}

.newsection {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $gray-semidle;
  border-radius: 10px;
  height: 150px;
  &__addsection {
    border: 1px solid $main-black;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    min-width: 150px;
    padding: 5px 0;
    cursor: pointer;
  }
  &__input {
    width: 90%;
    border: 8px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid $gray-semisold;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}
.optionboard {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  position: relative;

  &__label {
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.15px;
  }
  &__options {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__add {
    cursor: pointer;
  }
}

.taskboard {
  width: 100%;
  background-color: $gray-semidle;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &__header {
    width: 100%;

    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__header-img {
    margin-left: 20px;
  }
  &__header-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 10px;
    padding-right: 5px;
  }
  &__body {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }
  &__body-color {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background-color: $blue-tita;
    border-radius: 10px;
    padding: 0 5px;
    margin-right: 5px;
  }
  &__body-color_green {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background-color: $green-tita;
    border-radius: 10px;
    padding: 5px;
    margin-right: 5px;
  }
  &__footer {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
  }
}
.addtaskboard {
  width: 84.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $gray-basic;
  border: 1px solid $gray-semisold;
  border-radius: 10px;
  min-height: 400px;
  &__card {
    width: 90%;
    background-color: $gray-semidle;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &__header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  &__header-img {
    margin-left: 10px;
  }
  &__header-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 10px;
  }
  &__footer {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
  }
  &__button {
    width: 90%;
    padding: 3px 0;
    border: 1px solid $main-black;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
  }
}

.list {
  width: 95%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: scroll;
  min-height: 100%;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 250px;
  }
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.addtask {
  position: absolute;
  max-width: 350px;
  width: 100%;
  height: 250px;
  top: 45px;
  border: 1px solid $gray-basic-midle;
  border-radius: 3.5px;
  background-color: $main-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  &__groupform {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    position: relative;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.15px;
    margin-bottom: 5px;
  }
  &__assigndate {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.15px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  &__input {
    width: 100%;
    height: 30px;
    border: 1px solid $gray-basic-midle;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
  }
  &__listusers {
    width: 100%;
    max-height: 100px;
    border: 1px solid $gray-basic-midle;
    border-radius: 3.5px;
    background-color: $main-white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    z-index: 9;
    overflow-y: scroll;
  }
  &__user{
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    min-height: 30px;
    cursor: pointer;
  }
  &__user:hover{
    background-color: $gray-basic;
  }
  &__date-range {
    position: absolute;
    z-index: 9;
    top: 30px;
  }
  &__buttons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  &__button-cancel{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    border: 1px solid $main-black;
    border-radius: 4px;
    padding: 5px 0;
    cursor: pointer;
    width: 40%;
  }
  &__button-confirm{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: $main-white;
    background-color: $green-tita;
    padding: 5px 0;
    border-radius: 4px;
    cursor: pointer;
    width: 40%;
  }
}



