.main-content {
  display: flex;
  width: 100%;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-right: 20px;
  &__menu-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }
  &__image {
    margin-left: 30px;
  }
  &__item-menu {
    display: flex;
    align-items: center;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
  }

  &__item-menu-active {
    display: flex;
    align-items: center;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    background-color: $gray-light !important;
    background: url("./../../images/inicio/Union.svg") no-repeat 1% 50%;
  }

  &__item-menu:hover {
    background-color: $gray-light;
  }

  &__image-item {
    width: 22px;
    height: 22px;
    margin: 0;
    margin-left: 30px;
    margin-right: 15px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  width: 75%;
  position: relative;

  &__label {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 30px;
  }
}
.header {
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  &__first {
    width: 70%;
    display: flex;
    justify-content: center;
  }

  &__search {
    width: 70%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: $gray-basic;
    position: relative;
  }

  &__result-search {
    position: absolute;
    width: 100%;
    top: 35px;
    background-color: $main-white;
    border: 1px solid $gray-basic-midle;
    border-radius: 8px;
    overflow: hidden;
  }
  &__result-item {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
  }
  &__result-item:hover {
    background-color: $gray-basic;
  }
  &__search-image {
    margin: 10px;
  }
  &__input-search {
    background-color: $gray-basic;
    width: 75%;
    font-weight: 600;
    font-size: 16px;
  }
  &__input-search::placeholder {
    font-weight: 600;
    font-size: 16px;
  }
  &__second {
    display: flex;
    width: 30%;
  }
  &__image-user {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__img-user {
    width: 34px;
    height: 34px;
    border-radius: 50px;
  }
  &__name-user {
    width: 55%;
    display: flex;
    flex-direction: column;
  }
  &__label-name {
    width: 100%;
  }
  &__label-job {
    width: 100%;
  }
  &__logout {
    display: flex;
    align-items: center;
    width: 15%;
  }

  &__logout-button {
    background: url("../../images/inicio/logout.svg") no-repeat 50% 50%;
    background-size: 18px 18px;
    padding: 9px 9px;
    margin-left: 18px;
    cursor: pointer;
  }
}

.options-inicio-tasks {
  width: 100%;
  display: flex;
  margin-bottom: 40px;

  &__text {
    width: 60%;
    display: flex;
    align-items: center;
  }
  &__image {
    width: 20px;
    height: 10px;
    margin-right: 20px;
  }
  &__label {
    font-size: 20px;
    font-weight: 500;
  }
  &__content-button {
    width: 30%;
    display: flex;
    justify-content: center;
  }
  &__button {
    position: relative;
    border: 4px solid transparent;
    border-radius: 8px;
    background-color: $main-white;
    background-clip: padding-box;
    font-weight: bold;
    font-size: 14px;
    color: $green-tita;
    width: 70%;
    padding: 7px 0 4px 0;
    cursor: pointer;
  }
  &__button::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(to bottom, $green-tita 0%, $blue-tita 100%);
    content: "";
    z-index: -1;
    border-radius: 5px;
  }
}
.tasks {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.task {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  &__image-task {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $yellow-tita;
    border-radius: 5px;
  }
  &__text {
    width: 45%;
    display: flex;
    justify-content: space-around;
  }

  &__info {
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  &__chip {
    width: 12%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-midle;
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
  }
  &__date {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__estimating {
    width: 20%;
    display: flex;
  }
  &__options {
    width: 20%;
  }
  &__estimating-value {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $main-black;
    border-radius: 10px 0px 0px 10px;
  }
  &__estimating-spantext {
    width: 100%;
    font-size: 8px;
    color: $main-white;
    display: flex;
    justify-content: center;
    font-weight: 700;
  }
  &__estimating-spanhour {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: $main-white;
    display: flex;
    justify-content: center;
  }
  &__estimating-img {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green-tita;
  }
  &__estimating-value-real {
    width: 40%;
    background-color: $gray-semidle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 10px 10px 0px;
  }
  &__estimating-spanreal {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 8px;
    font-weight: 700;
  }
  &__estimating-spanrealhour {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
  }
  &__options {
    width: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;
  }
  &__options-option {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-sold;
    font-weight: 700;
    font-size: 14px;
  }
}

.option-project {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  &__image {
    width: 20px;
    height: 10px;
    margin-right: 20px;
  }
  &__label {
    font-size: 20px;
    font-weight: 500;
  }
}

.projects {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.home-project {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  margin-bottom: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 160px;
    border-radius: 10px 10px 0px 0px;
  }

  &__content-head {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__content-head-title {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    background: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.02) 100%
    );
    border-radius: 20px;
    color: $main-white;
  }

  &__content-footer {
    width: 90%;
    height: 21px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  &__content-footer-item {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-white;
    border-radius: 50%;
    background-color: $main-black;
    border: 1px solid $main-white;
    font-size: 12px;
    font-weight: 400;
  }

  &__content-footer-img {
    width: 17px;
    border-radius: 50%;
  }

  @for $i from 1 through 18 {
    &__content-footer-item:nth-child(#{$i}) {
      right: $i * 15px;
      z-index: 30-$i;
    }
  }

  &__footer {
    border-radius: 0px 0px 20px 20px;
    background-color: $gray-basic;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &__footer-label {
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
  }
}

.home-project:nth-child(1) {
  .home-project__content {
    background-color: $blue-tita;
  }
}

.home-project:nth-child(2) {
  .home-project__content {
    background-color: $green-tita;
  }
}

.home-project:nth-child(3) {
  .home-project__content {
    background-color: $yellow-tita;
  }
}

.home-project:nth-child(4) {
  .home-project__content {
    background-color: $yellow-tita;
  }
}

.home-project:nth-child(5) {
  .home-project__content {
    background-color: $blue-tita;
  }
}

.home-project:nth-child(6) {
  .home-project__content {
    background-color: $green-tita;
  }
}

.home-task {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  &__image-task {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  &__img {
    opacity: 0.2;
  }
  &__text {
    width: 45%;
    display: flex;
    justify-content: space-around;
  }

  &__info {
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  &__chip {
    width: 12%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-midle;
    border-radius: 20px;
    font-size: 10px;
    font-weight: bold;
  }
  &__date {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__estimating {
    width: 20%;
    display: flex;
  }
  &__options {
    width: 20%;
  }
  &__estimating-value {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $main-black;
    border-radius: 10px 0px 0px 10px;
  }
  &__estimating-spantext {
    width: 100%;
    font-size: 8px;
    color: $main-white;
    display: flex;
    justify-content: center;
    font-weight: 700;
  }
  &__estimating-spanhour {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: $main-white;
    display: flex;
    justify-content: center;
  }
  &__estimating-img {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green-tita;
    cursor: pointer;
  }

  &__estimating-img2 {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(139.83deg, $green-tita 3.8%, $blue-tita 82.11%);
    cursor: pointer;
  }
  &__estimating-value-real {
    width: 40%;
    background-color: $gray-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 10px 10px 0px;
  }

  &__estimating-value-real2 {
    width: 40%;
    background-color: $green-tita;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 10px 10px 0px;
  }
  &__estimating-spanreal {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 8px;
    font-weight: 700;
  }
  &__estimating-spanrealhour {
    width: 50%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    text-align: right;
    margin-top: 4px;
    margin-right: 2px;
    height: 20px;
    pointer-events: none;
  }

  &__estimating-spanrealhour2 {
    width: 50%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    text-align: right;
    margin-top: 4px;
    margin-right: 2px;
    height: 20px;
  }
  &__options {
    width: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;
  }
  &__options-option {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-sold;
    font-weight: 700;
    font-size: 14px;
  }

  &__h-letter {
    font-family: Be Vietnam;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    width: 50%;
  }

  &__estimating-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.home-task:nth-child(3n + 1) {
  .home-task__image-task {
    background-color: $yellow-tita;
  }
}

.home-task:nth-child(3n + 2) {
  .home-task__image-task {
    background-color: $blue-tita;
  }
}

.home-task:nth-child(3n + 3) {
  .home-task__image-task {
    background-color: $green-tita;
  }
}

.MuiFab-root {
  background-color: #141414 !important;
  position: fixed !important;
  right: 0;
  bottom: 0;
  margin: 40px !important;
  color: white !important;
}

.notification {
  background-color: $main-black !important;
  position: fixed !important;
  right: 0;
  bottom: 0;
  margin: 40px !important;
  color: white !important;
}

.notification-popup {
  width: 380px;
  border-radius: 5px;
  border: solid 1px $main-black;
  padding: 10px;
  background-color: $gray-basic;

  &__span {
    width: 95%;
    font-size: 13px;
    border-bottom: solid 1px $gray-semisold;
    padding-bottom: 8px;
    display: flex;
  }

  &__cancel {
    font-size: 13px;
    padding-bottom: 5px;
    color: $main-white;
    background-color: $gray-semisold;
    border-radius: 50px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-top: 10px;
    float: right;
    margin-left: 10px;
  }

  &__cancel:hover {
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  }

  &__buttons {
    width: 100%;
    display: table;
    justify-content: space-between;
    align-items: center;
  }
}
