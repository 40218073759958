.headerusers {
  width: 98%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.25px;
    width: 60%;
  }
  &__select {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $gray-semidle;
    border-radius: 4px;
    padding: 6.5px 0;
    margin-bottom: 2px;
  }
  &__team {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $gray-semisold;
  }
  &__whichteam {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: $main-black;
  }
  &__arrow {
    margin-top: 4px;
    width: 10px;
    height: 6px;
  }
  &__button {
    width: 13%;
    display: flex;
    border-radius: 10px;
    background-color: $green-tita;
    color: $main-white;
    justify-content: space-around;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    padding: 8px 20px;
    cursor: pointer;
  }
}

.formusers {
  width: 98%;
  display: flex;
  flex-direction: column;

  &__inputs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 45px;
  }
}

.inputuser {
  width: 47%;
  display: flex;
  flex-direction: column;

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
  }
  &__input {
    width: 95%;
    height: 40px;
    border: 1px solid $gray-basic-midle;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
  }
}

.listprofileteam {
  width: 47%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    height: 40px;
    border: 1px solid $gray-basic-midle;
    color: $gray-sold;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    border-radius: 3.5px;
    padding-left: 10px;
  }
  &__img {
    margin: 4px 10px 0 0;
    cursor: pointer;
  }
  &__lists {
    width: 95%;
    border: 1px solid $gray-basic-midle;
    position: absolute;
    z-index: 9;
    top: 75px;
    border-radius: 3.5px;

    background-color: $main-white;
  }
  &__item {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
  }
  &__item:hover {
    background-color: $gray-basic;
  }
}

.tableuser {
  width: 98%;
  display: flex;
  flex-direction: column;
  background-color: $gray-basic;
  border-radius: 4px;
}

.headertableusers {
  width: 100%;
  display: flex;
  background-color: $main-white;
  &__head:first-child {
    width: 31%;
  }
  &__head {
    width: 23%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 20px;
    height: 45px;
    display: flex;
    align-items: center;
  }
}

.bodytableusers {
  width: 100%;
  display: flex;

  &__user {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }

  &__user-text {
    width: 80%;
    display: flex;
    align-items: center;
  }
  &__img {
    margin: 0 15px 0 20px;
  }
  &__options2 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green-tita;
  }
  &__options1 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(139.83deg, #80e593 3.8%, #52c7cf 82.11%);
  }
  &__options_edit {
    cursor: pointer;
  }
  &__body {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    padding-left: 20px;
    height: 45px;
    display: flex;
    align-items: center;
  }
  &__body:nth-child(2) {
    width: 30%;
  }
  &__body:nth-child(3) {
    width: 15%;
  }
  &__body:nth-child(4) {
    width: 15%;
  }
  &__body:last-child {
    width: 10%;
    justify-content: center;
    font-size: 8px;
  }

  &__options {
    width: 10%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  &__ok {
    color: $green-tita;
    cursor: pointer;
  }
  &__cancel {
    color: $error-color;
    cursor: pointer;
  }
  &__delete {
    margin-right: 10px;
    display: none;
    cursor: pointer;
  }
}
.bodytableusers:hover .bodytableusers__delete {
  display: block;
}

.user-sucess-text{
  position: fixed;
  animation-duration: 0.2s;
  animation-name: slide;
  background-color: $green-tita;
  bottom: 100px;
  right: 0px;
  padding: 20px;
  border-radius: 20px 0px 0px 20px;
  color:$main-white;
  box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
}

.user-fail-text{
  position: fixed;
  animation-duration: 0.2s;
  animation-name: slide;
  background-color: $error-color;
  bottom: 30px;
  right: 0px;
  padding: 20px;
  border-radius: 20px 0px 0px 20px;
  color:$main-white;
  box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
}

.display-none{
  display: none;
}